
import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import {useTranslation} from 'react-i18next';
import css from './support.module.scss';
import Footer from "../components/shared/Footer/Footer";
import SupportSolutionsList from "../components/sections/supportSolutionsList/SupportSolutionsList";
import { getRoutes } from '../components/shared/routes';
import ContactUs from "../components/pages/ContactPage/partials/ContactUs";

const SupportPage = () => {
  const { t } = useTranslation();
  const pageTitle = `${getRoutes().Support.pageTitle}`;

  return (
    <Layout>
      <SEO title={pageTitle} description={'Professional support'}/>

      <div className={css.wrapper}>

        <div className={css.SupportSolutionsList}>
            <SupportSolutionsList/>
        </div>

        <div className={css.ContactUs}>
            <ContactUs />
          </div>

        <div className={css.Footer}>
          <Footer/>
        </div>

      </div>
    </Layout>
  );
};

export default SupportPage;
