import React from "react";
import css from './supportSolutionsList.module.scss';
import BoxHeader from "../../shared/BoxHeader/BoxHeader";
import MainButton from "../../shared/MainButton/MainButton";
import {Link} from "gatsby";
import {getLink, getRoutes} from "../../shared/routes";
import OptiwaiPlusLogo from "../../../images/logos/optiwai_plus_full_logo.svg";

const SupportSolutionsList = () => {
   const renderListItem = (logo, subpage) => {
    return (
        <div className={css.solution}>
            <div className={css.logoWrapper}>
              <img src={logo} alt="" />
            </div>
            <Link to={getLink(subpage)}>
                <MainButton
                  label="User Guide"
                  isLink={false}
                  className={css.learnMoreButton}
                  filled
                  color={'green'}
                />
             </Link>
        </div>
    );
   };

  return (
    <div className={css.wrapper}>
      <BoxHeader lineColor={'green'} headerType={'h1'} >Support</BoxHeader>
      <div className={css.contentWrapper}>
          {renderListItem(OptiwaiPlusLogo, [getRoutes().UserGuide])}
      </div>
    </div>
  )
};

export default SupportSolutionsList;
